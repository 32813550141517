import { Button } from "@blueprintjs/core";

function CustomButton({ children, intent, fill=false, onClick }) {
  return (
    <Button
      style={{
        borderRadius: "50px",
        boxShadow: "2px 2px 5px 1px rgba(0, 0, 0, .2)",
      }}
      intent={intent}
      fill={fill}
      onClick={onClick}
    >
      {children}
    </Button>
  );
}

export default CustomButton;
