import { useState } from "react";
import { Intent, H2, H3, Tag, Button, H5, Blockquote } from "@blueprintjs/core";
import CustomButton from "../../components/CustomButton/CustomButton";
import "./Docs.css";
import logo from "../../tts_logo.png"
import GettingStarted from "../GettingStarted/GettingStarted";

function Intro() {
  return (
    <div style={{ textAlign: "left" }}>
      <H2 className="App-title" style={{ fontSize: "30pt" }}>
        <img alt="logo" src={logo} width={46}/>Universal DiD Resolver
        <span style={{ marginLeft: "5px" }}>
          <Tag intent={Intent.PRIMARY} round>
            v0.1.0
          </Tag>
        </span>
      </H2>
      <H3>
        An application programming interface designed to make resolving
        distributed identities quick and easy.
      </H3>
      <H5>What is a DiD?</H5>
      <Blockquote>
        A globally unique persistent identifier that does not require a
        centralized registration authority because it is generated and/or
        registered cryptographically. The generic format of a DID is defined in
        the DID Core specification{" "}
        <a href="https://www.w3.org/TR/did-core/">[DID-CORE]</a>. A specific DID
        scheme is defined in a DID method specification. Many—but not all—DID
        methods make use of distributed ledger technology (DLT) or some other
        form of decentralized network. <br />
        <br />
        <a href="https://www.w3.org/TR/did-use-cases/#intro">
          https://www.w3.org/TR/did-use-cases/#intro
        </a>
      </Blockquote>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto suscipit
        veniam labore molestias non at nemo, laborum vitae voluptas. Aspernatur
        repudiandae voluptate officiis alias velit incidunt non nemo quis
        maxime.
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Cum officiis
        nisi magnam minus cumque quam dolore sint quod eligendi sequi, tempore
        officia ab nam voluptatibus facilis. Provident est deserunt doloribus.
      </p>
    </div>
  );
}

function Docs() {
  const [selection, setSelection] = useState("intro");

  const getPage = (page) => {
    switch (page) {
      case "gettingStarted":
        return GettingStarted();
      default:
        return Intro();
    }
  };

  return (
    <div className="App-page">
      <div className="docs-container">
        <div className="docs-left-col">
          <div style={{ textAlign: "left" }}>
            <H3 className="App-title" style={{ textAlign: "center" }}>
              Contents
            </H3>
            <CustomButton
              intent={Intent.PRIMARY}
              fill
              onClick={() => setSelection("gettingStarted")}
            >
              Get Started
            </CustomButton>
            <Button
              className="docs-menu-item"
              active={selection === "intro" ? true : false}
              intent={Intent.PRIMARY}
              fill
              minimal
              onClick={() => setSelection("intro")}
            >
              Introduction
            </Button>
            <Button
              className="docs-menu-item"
              active={false}
              intent={Intent.PRIMARY}
              fill
              minimal
            >
              Get DiD
            </Button>
          </div>
        </div>
        <div className="docs-right-col">{getPage(selection)}</div>
      </div>
    </div>
  );
}

export default Docs;
