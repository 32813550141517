// import logo from "./logo.svg";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.scss";
import NavBar from "./components/NavBar/NavBar";
import Docs from "./pages/Docs/Docs";

// const darkTheme = "App-dark bp3-dark"
const lightTheme = "App"

function App() {
  const theme = lightTheme;
  return (
    <Router>
      <div className={theme}>
        <NavBar/>
        <Switch>
          <Route path="/">
            <Docs/>
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
