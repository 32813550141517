import { Navbar, Button, Alignment, Tag, Intent } from "@blueprintjs/core";
import logo from "../../tts_logo.png"

function NavBar() {
  return (
    <Navbar fixedToTop>
      <div style={{ maxWidth: "960px", margin: "auto" }}>
        <Navbar.Group align={Alignment.LEFT}>
          <Navbar.Heading>
            <div className="App-title">
              <img alt="logo" src={logo} width={20}/><b>Universal DiD Resolver</b>
              <span>
                <Tag
                  intent={Intent.PRIMARY}
                  round
                  style={{ marginLeft: "4px" }}
                >
                  v0.1.0
                </Tag>
              </span>
            </div>
          </Navbar.Heading>
          <Navbar.Divider />
          <Button minimal intent={Intent.PRIMARY} icon="home" text="Home" />
          <Button
            minimal
            intent={Intent.PRIMARY}
            icon="document"
            text="Files"
          />
        </Navbar.Group>
        <Navbar.Group align={Alignment.RIGHT}>
          <Button minimal intent={Intent.PRIMARY} icon="settings"/>
          <Button minimal intent={Intent.PRIMARY} icon="lightbulb"/>
        </Navbar.Group>
      </div>
    </Navbar>
  );
}

export default NavBar;
