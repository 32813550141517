import { Code, Divider, H2, H3 } from "@blueprintjs/core";

function GettingStarted() {
  return (
    <div style={{ textAlign: "left" }}>
      <H2 className="App-title" style={{ fontSize: "30pt" }}>
        Getting Started
      </H2>
      <H3>
        An application programming interface designed to make resolving
        distributed identities quick and easy.
      </H3>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga aperiam
        quo, veniam mollitia similique quae porro officia reprehenderit
        laboriosam perspiciatis voluptatibus nemo repellendus quia odit facilis,
        atque expedita ipsum placeat.
      </p>
      <br/>
      <Divider style={{marginLeft: "0px", marginRight: "0px"}}/>
      <H3>API Description</H3>
      <Divider style={{marginLeft: "0px", marginRight: "0px"}}/>
      <Code>
          This is a code block.
      </Code>
    </div>
  );
}

export default GettingStarted;
